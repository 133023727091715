<template>
    <div class="to_confirm">
        <div>确认设备故障单</div>
        <!-- 设备选择 -->
        <van-grid :column-num="1" :gutter="10" class="device_info">
            <van-grid-item>
                <!-- <div class="item_style">{{ `编号:01` }}</div>
                <div class="item_style">{{ `A区1线-组框机1号-ABCABCAB2023/10/2310:42已持续20分钟组件吸附异常/破真空异常` }}</div> -->
                <div class="item_style">{{ `编号:${maintenanceItem.id}` }}</div>
                <div class="item_style">{{ `${maintenanceItem.info}` }}</div>
            </van-grid-item>
        </van-grid>
        <!-- 确认人 -->
        <div class="form_label">确认人</div>
        <el-autocomplete class="inline-input" v-model="confirmHuman" :fetch-suggestions="querySearch" placeholder="请选择确认人"
            :trigger-on-focus="false" @select="handleSelect"></el-autocomplete>
        <!-- 确认故障 -->
        <div class="form_label">确认故障</div>
        <el-select v-model="isConfirm" placeholder="请确认故障类型是否正确">
            <el-option v-for="item, i in isRight" :key="i" :label="item.label" :value="item.value">
            </el-option>
        </el-select>
        <div v-if="isConfirm !== false">
            <!-- 确认步骤 -->
            <div class="form_label">确认步骤</div>
            <el-input type="textarea" autosize placeholder="按步骤填写" v-model="confirmationSteps">
            </el-input>
            <!-- 确认故障现象 -->
            <div class="form_label">确认故障现象</div>
            <el-select v-model="faultPhenomenon" placeholder="请确认故障类型">
                <el-option v-for="item in maintenanceList" :key="item.value" :label="item.label" :value="item.value">
                </el-option>
            </el-select>
            <!-- 是否已修复 -->
            <div class="form_label">是否已修复</div>
            <el-select v-model="isRepair" placeholder="请确认故障是否已修复">
                <el-option v-for="item, i in isRight" :key="i" :label="item.label" :value="item.value">
                </el-option>
            </el-select>
            <!-- 维修人 -->
            <div class="form_label" v-if="isRepair == false || isRepair == ''">指定维修人</div>
            <el-autocomplete v-if="isRepair == false || isRepair == ''" class="inline-input" v-model="maintenancePersonnel"
                :fetch-suggestions="querySearch" placeholder="请指定维修人" :trigger-on-focus="false"
                @select="handleSelect"></el-autocomplete>
        </div>
        <!-- 操作按钮 -->
        <div class="opreation_button">
            <van-button type="primary" @click="onConfirm">保存</van-button>
            <van-button type="default" @click="onCancel">取消</van-button>
        </div>
        <van-dialog v-model="cancelDialog" title=" " show-cancel-button @confirm="cancelConfirm" @cancel="cancelClose">
            <div>确认要取消填写么</div>
        </van-dialog>
    </div>
</template>

<script>
import Vue from 'vue'

import { Grid, GridItem, Button, Notify, Dialog } from 'vant';

Vue.use(Grid);
Vue.use(GridItem);
Vue.use(Button);
Vue.use(Notify);
Vue.use(Dialog);

export default {
    data() {
        return {
            restaurants: [],
            cancelDialog: false,
            confirmHuman: sessionStorage.getItem('username'),
            isConfirm: '',
            time: '',
            userList: [{
                value: '选项1',
                label: '员工一'
            }, {
                value: '选项2',
                label: '员工二'
            }],//人员列表
            isRight: [
                {
                    value: true,
                    label: '是'
                }, {
                    value: false,
                    label: '否'
                }
            ],
            maintenanceList: [{
                value: '选项1',
                label: '线体气缸上位异常'
            }, {
                value: '选项2',
                label: '长边1边框归正紧位异常'
            }, {
                value: '选项3',
                label: '短边1夹抓松位异常'
            }],
            faultPhenomenon: '',
            confirmationSteps: '',
            isRepair: undefined,
            maintenancePersonnel: '',
            maintenanceItem: {}
        }
    },
    methods: {
        onConfirm() {
            if (this.confirmHuman != '' && this.isConfirm != '' && this.faultPhenomenon != '' && this.isRepair != '' || this.maintenancePersonnel != '') {
                let id = this.$route.query.id
                // 更改已确认
                let faultList = JSON.parse(sessionStorage.getItem('faultData'))
                faultList.find(item => {
                    if (item.id == id) {
                        item.isConfirm = true
                    }
                })
                console.log(faultList);
                sessionStorage.setItem('faultData', JSON.stringify(faultList))
                // 添加进待维修列表
                let data = JSON.parse(sessionStorage?.getItem('maintenanceData'))?.length ? JSON.parse(sessionStorage.getItem('maintenanceData')) : []
                let maintenancePersonnel = this.maintenancePersonnel
                if (this.isRepair == true) {
                    maintenancePersonnel = sessionStorage.getItem('username')
                }
                let item = JSON.parse(sessionStorage.getItem('faultData')).find(item => item.id == id).info
                data.push({ id: id, info: item, maintenancePersonnel: maintenancePersonnel, isRepair: false })
                sessionStorage.setItem('maintenanceData', JSON.stringify(data))
                this.$router.push({
                    path: '/issuccess',
                    query: { id: id }
                })
            } else {
                if (this.isConfirm == false) {
                    let id = this.$route.query.id
                    // 更改已确认
                    let faultList = JSON.parse(sessionStorage.getItem('faultData'))
                    faultList.find(item => {
                        if (item.id == id) {
                            item.isConfirm = true
                        }
                    })
                    sessionStorage.setItem('faultData', JSON.stringify(faultList))
                    this.$router.push({
                        path: '/issuccess',
                        query: { id: -1 }
                    })
                } else {
                    Notify('请填写完整的确认设备故障单')
                }
            }

        },
        // 点击取消
        onCancel() {
            this.cancelDialog = true
        },
        // 取消操作弹窗确认
        cancelConfirm() {
            this.confirmHuman = ''
            this.isConfirm = ''
            this.confirmationSteps = ''
            this.faultPhenomenon = ''
            this.isRepair = ''
            this.maintenancePersonnel = ''
            this.$router.push('/faultlist')
        },
        // 取消操作弹窗取消
        cancelClose() { },
        // 人员搜索
        async querySearch(queryString, cb) {
            // 请求用户数据
            await this.$axios.get("http://" + this.ip + "/python/User/?search=" + queryString).then(
                (response) => {
                    console.log(response.data.res);
                    response.data.res.map(item => {
                        return item.value = item.name
                    })
                    cb(response.data.res)
                },
                (error) => {
                    console.log(error.message);
                    this.$message.error(this.$t("msg.user_info_f"));
                }
            );
            // var restaurants = this.restaurants;
            // var results = queryString ? restaurants.filter(this.createFilter(queryString)) : restaurants;
            // // 调用 callback 返回建议列表的数据
            // cb(results);
        },
        createFilter(queryString) {
            return (restaurant) => {
                return (restaurant.value.indexOf(queryString) === 0);
            };
        },
        // loadAll() {
        //     return [{ "value": "黄晨晨" },
        //     { "value": "王子寒" },]
        // },
        handleSelect(item) {
            console.log(item);
        }

    },
    created() {
        let id = this.$route.query.id
        this.maintenanceItem = JSON.parse(sessionStorage.getItem('faultData')).find(item => item.id == id)
    },
    mounted() {
        // this.restaurants = this.loadAll();
    }
}
</script>

<style lang="scss" scoped>
.to_confirm {
    background-color: #f7f8fa;
    height: 100vh;
    box-sizing: border-box;
    padding-top: 1.25rem;

    .device_info {
        margin: 0 .625rem;
        height: 5rem;
        margin-top: .625rem;


        .item_style {
            font-size: 0.75rem;
            text-align: left;
            word-break: break-all;
            width: 100%;
        }
    }

    .form_label {
        text-align: left;
        padding-left: 1.25rem;
        padding-bottom: 0.625rem;
        padding-top: 0.625rem;
        font-size: 0.75rem;

    }

    .el-select {
        width: calc(100% - 2.5rem);
    }

    .el-autocomplete {
        width: calc(100% - 2.5rem);
    }

    .el-input {
        width: calc(100% - 2.5rem);
    }

    ::v-deep .el-textarea__inner {
        width: calc(100% - 2.5rem);
        resize: none;
        margin-left: 1.25rem;
    }

    .opreation_button {
        position: fixed;
        bottom: 0;
        width: 100%;

        .van-button {
            border-radius: 0;
            width: 50%;
        }
    }
}
</style>